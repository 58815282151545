h1 {
    color: #F59E0B;
}

body {
    color: white;
}

.footer {
    background-color: #1F2937;
}

a:link {
    color: white;
    padding: 5px;
}

a:visited {
    color: white;
    padding: 5px;
  }

a:hover {
color: white;
background-color: rgba(255,255,255,0.2);
border-top-left-radius: 12px;
border-top-right-radius: 12px;
padding: 5px;
}

.App {
    display: flex;
    justify-content: center;
}

.projectLink {
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding-bottom: 5px;
}